a:hover {
  text-decoration: none;
}

p {
  text-align: justify;
}

#headershadow {
  background: url(../public/images/bg-main.png) repeat-x;
  height: 10px;
  position: absolute;
  width: 100%;
  z-index: 10;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-shadow: 1px 1px 1px #FFF;
}

.headerContent {
  line-height: 25px !important;
  text-align: justify !important;
  font-size: 14px;
}

#navlist li {
  padding-top: 4px;
  padding-left: 25px;
  background-image: url(../public/images/spmicon.png);
  background-repeat: no-repeat;
  background-position: 0 .5em;
  background-size: 16px 16px;
}

#navlist {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.headerFrame {
  background: url(../public/images/bg-tools-b.jpg) 0 100% no-repeat;
  padding: 14px 25px;
  border-radius: 8px;
  margin-top: 5%;
  margin-bottom: 18px;
  background-size: cover;
}

.headerFrame h1 {
  font-size: 2.1em;
  font-family: Arial, ColaborateLightRegular, sans-serif;
}

#mainCarousel .carousel-inner .carousel-item img {
  height: 350px !important;
  width: 100%;
}

#list {
  list-style: none;
}

#list li {
  padding-top: 4px;
  padding-left: 25px;
  background-repeat: no-repeat;
  background-position: 0 .5em;
  background-size: 16px 16px;
  background-image: url(../public/images/spmicon.png);
}

#list li a {
  color: #456793;
}

#footer,
#footer_bottom {
  color: #DDD !important;
}

#footer .footer-area {
  width: 980px;
  margin: 0 auto;
  position: relative;
}

#footer .footer-wrapper {
  width: 980px;
  overflow: hidden;
}

#footer .footer-holder {
  width: 100%;
  overflow: hidden;
  padding: 45px 0 25px 6px;
}

#footer .one_fourth,
#footer .one_fourth_last {
  float: left !important;
  width: 227px !important;
  margin-right: 23px !important;
}

#footer .one_fourth_last {
  margin-right: 0 !important;
  float: right !important;
  padding-bottom: 20px;
  margin-top: -187px;
}


#footer .footer_post {
  padding: 2px 0 16px;
}

.partnerbox {
  width: 110px;
  height: 60px;
  float: left;
  margin-bottom: 5px;
}

.footer-holder ul {
  margin: 0;
  padding: 0;
  color: #999;
  list-style: none;
}

.social_icons .facebook {
  background: url(../public/images/social-icons/social-sprite.png) 0 -64px no-repeat;
}

.social_icons .linkedin {
  background: url(../public/images/social-icons/social-sprite.png) 0 -160px no-repeat;
}

.footer-holder a {
  color: #6984AB !important;
}

.social_icons a {
  height: 32px;
  display: block;
  border: 0;
  text-decoration: none;
  outline: 0;
  text-indent: 3.5em;
  line-height: 32px;
}

#mainCarousel .carousel-item:hover img {
  opacity: 0.4;
  transition:opacity 2s;
}

#mainCarousel h3 {
  color: black;
  font-weight: bold;
}

#footer h3, h6 {
  border-bottom: 1px solid #516D8A !important;
  text-transform: uppercase;
  display: block;
  font-size: 13px;
  letter-spacing: .5px;
  border-bottom: 1px solid #3E4055;
  padding-bottom: 7px;
  margin-bottom: 16px;
  font-weight: 700;
}

#footer .blogroll a {
  color: #FFF !important;
  font-weight: 200;
}

.large_fire,
.large_fire span,
.medium_fire,
.medium_fire span,
.small_fire,
.small_fire span {
  background-image: url(../public/images/bg-buttons-sprite-fire.png) !important;
  color: #CC5600;
  font-size: 13px;
  font-weight: 700;
}

.small_button {
  margin: 5px 10px 5px 0;
  padding: 0 21px 0 0;
  font-size: .9em;
  background: transparent url(../public/images/bg-buttons-sprite.png) 100% -43px no-repeat scroll;
  color: #F4F4F2;
}

.small_button span {
  margin: 0;
  padding: 16px 0 17px 21px;
  line-height: 10px;
  background: transparent url(../public/images/bg-buttons-sprite.png) 0 0 no-repeat scroll;
}

.ka_button,
.ka_button:hover {
  text-decoration: none !important;
  border: 0;
  display: inline-block;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #DDD !important;
}

.read_learn_more_icon {
  background-image: url(../public/images/icon_contact.png) !important;
  background-repeat: no-repeat;
  float: left;
  height: 26px;
  margin-left: -8px;
  margin-right: 15px;
  margin-top: -7px;
  position: relative;
  width: 34px;
}

#footer .ka_button {
  background-color: white;
  padding: 16px;
}

#sidebar {
  background: #E7E9E6;
  border-radius: 10px;
  padding-bottom: 15px;
}

#sidebar h3 {
  color: #222;
  font-size: 21px;
  padding-top: 15px;
}

#sidebar a,
.content_full_width a,
p a strong {
  color: #456793;
}